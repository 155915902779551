.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@tailwind base;

@tailwind components;

@tailwind utilities;

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.carousel-item {
  height: 100vh; /* Full viewport height */
}

.carousel img {
  object-fit: cover; /* Ensure the image covers the entire carousel */
  height: 100%;
  position: relative;
  z-index: -1;
  opacity: 1;
}

.custom-caption {
  position: absolute; /* Override Bootstrap's default flexbox positioning */
  bottom: 25% !important; /* Move the caption to 75% height (25% from the bottom) */
  left: 0;
  right: 0;
  text-align: center; /* Center the text */
  z-index: 2; /* Ensure the caption stays above the image */
  transform: translateY(-30%);
}

.carousel-caption h5,
.carousel-caption p {
  color: #fff; /* Ensure the text is readable */
}

.navbar {
  z-index: 1; /* Ensure the navbar stays above the carousel */
}

.carousel-caption {
  z-index: 2; /* Ensure captions are readable above the image */
}



.navbar-transparent {
  background-color: rgba(0, 0, 0, 0.3) !important; /* Adjust the RGBA value for the transparency level */
  backdrop-filter: blur(10px); /* Optional: Add a blur effect for better aesthetics */
  border: none; /* Remove default border */
}

.navbar-transparent .navbar-brand,
.navbar-transparent .nav-link {
  color: #fff; /* Set text color to white for better contrast */
}

/* .navbar-transparent .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 6h16M4 12h16m-7 6h7'/%3E%3C/svg%3E");
} */


hr.style-four {
  height: 20px;
  border: 0;
  box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.5);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
