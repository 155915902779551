.contact-form-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f7f9fc;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.card-container {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 600px;
  transition: box-shadow 0.3s ease;
}

.card-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card-title {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  color: #333;
}

.form-section {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  width: 100%;
}

.form-control {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.2);
}

.error-message {
  color: #e74c3c;
  font-size: 0.875em;
  margin-top: 5px;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 12px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-btn:hover {
  background: #0056b3;
  transform: translateY(-2px);
}

.submit-btn:disabled {
  background: #b0bec5;
  cursor: not-allowed;
}
